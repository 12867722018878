import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


class WebDevDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Service Details' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">WEBSITE DESIGN & DEVELOPMENT</h2>
                                    <p>There's so many possibilities on the wonderful world wide web. Let's explore together.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/service-01.png" alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>My job is to help you attain and refine a web presence that illustrates your brand's personality, mission and vision.</p>
                                                    <p>Using fundamental web principles enables us not only to spread brand awareness, but to help monetize and scale your business.</p>
                                                    <h4 className="title">Process Overview</h4>
                                                    <ul className="liststyle">
                                                        <li>Brand Discovery <span role="img" aria-label="investigator emoji">🕵🏾‍♀️</span></li>
                                                        <li>Design Concept <span role="img" aria-label="artist emoji">👩🏾‍🎨</span></li>
                                                        <li>Development & Implementation <span role="img" aria-label="developer emoji">👩🏾‍💻</span></li>
                                                        <li>Testing & Iteration <span role="img" aria-label="test tube emoji">🧪</span></li>
                                                        <li>Launch <span role="img" aria-label="rocket emoji">🚀</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>Before we get started, make sure you watch my video on the difference between a custom and customized website on this page. There are absolutely so many ways, frameworks and platforms to achieve the grand vision. So it's very important to level set expectations when including your timeline and budget into your final product.</p>
                                                    <p>I want you to feel empowered in every decision while taking such a big step for your business, and held in my dutiful care.</p>
                                                    <h4 className="title">My Promise</h4>
                                                    <ul className="liststyle">
                                                        <li>Timely Communication</li>
                                                        <li>Attractive Designs</li>
                                                        <li>Intuitive User Experience</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/portfolio/related-image-02.png" alt="Service Images"/>
                                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='NYHXsV_Sgqs' onClose={() => this.setState({isOpen: false})} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button>
                                                    <p>Example Website</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default WebDevDetails;